.formContainer {
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .formWrapper {
        background-color: white;
        padding: 20px 60px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .logo {
            color: darkblue;
            font-weight: bold;
            font-size: 24px;
        }

        .title {
            color: #5d5b8d;
            font-size: 12px;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 15px;

            input {
                padding: 15px;
                border: none;
                width: 250px;
                border-bottom: 1px solid #a7bcff;

                &::placeholder {
                    color: rgb(175, 175, 175);
                }
            }
            
            button {
                background-color: #7b96ec;
                color: white;
                padding: 10px;
                font-weight: bold;
                border: none;
                cursor: pointer;
            }

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #8da4f1;
                font-size: 12px;
                cursor: pointer;

                img {
                    width: 32px;
                }
            }
        }

        p {
            color: #5d5b8d;
            font-size: 12px;
            margin-top: 10px;
        }
    }
}

.home {
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        border: 1px solid white;
        border-radius: 10px;
        width: 85%;
        height: 90%;
        display: flex;
        overflow: hidden;

        .sidebar {
            flex: 1;
            width: 40%;
            background-color: #3e3c61;

            .navbar {
                display: flex;
                align-items: center;
                background-color: #2f2d52;
                height: 50px;
                padding: 10px;
                justify-content: space-between;
                color: #ddddf7;

                .logo {
                    font-weight: bold;
                }

                .user {
                    display: flex;
                    gap: 10px;

                    img {
                        background-color: #ddddf7;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    button {
                        background-color: #5d5b8d;
                        color: #ddddf7;
                        font-size: 10px;
                        border: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }
            }

            .search {
                border-bottom: 1px solid gray;

                .searchForm {
                    padding: 10px;

                    input {
                        background-color: transparent;
                        border: none;
                        color: white;
                        outline: none;

                        &::placeholder {
                            color: lightgray;
                        }
                    }
                }
            }    
            
            .userChat {
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: white;
                cursor: pointer;

                &:hover {
                    background-color: #2f2d52;
                }
                
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .userChatInfo {
                    span {
                        font-size: 18px;
                        font-weight: 500;
                    }
                    p {
                        font-size: 14px;
                        color: lightgray;
                    }
                }

                &.selected{
                    background-color: #2f2d52;
                }
            }            
        }

        .chat {
            flex: 2;
            background-color: white;
            background-image: 100%;
            background-image: url('../src/images/Chatbackground.png');

            .chatInfo {
                height: 50px;
                background-color: #5d5b8d;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                color: lightgray;


                .selectedUser{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img {
                        background-color: #ddddf7;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        object-fit: cover;
                        cursor: pointer;
                    }

                    h3{
                        cursor: pointer;
                    }
                    .lastseen{
                        font-size: 12px;
                        color:black
                    }
                }


                .chatIcons {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img {
                        height: 24px;
                        cursor: pointer;
                        

                        &:hover{
                            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 50%;
                            padding: 2px;
                                
                        }
                        
                    }
                    button {
                        border: none;
                        padding: 10px 15px;
                        color: white;
                        background-color: #8da4f1;
                        cursor: pointer;
                        border-radius: 20px;
                    }

                 

                }
            }

            .present{
                background-color: black;
                color: white;                
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: calc(100% - 50px);
                object-fit: cover;

                .main{
                    background-color: black;
                    color: white;
                    height: calc(100% - 120px); 
                }

                .options{
                    position: relative;
                    background-color: white;
                    color: #2f2d52;
                    height: 50px;
                    width: 50%;
                    //padding: 5px;
                    //padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #ccc;
                    border-radius: 10px 10px 10px 10px;
                    z-index: 1000;
                    opacity: 0.5;                             
                }

                button{
                    position: absolute;
                    //align-items: end;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    border: white;
                    cursor: pointer;
                    z-index: 1000;
                    opacity: 0.5;

                    &:hover{
                        background-color: #e0e0e0;
                    }
                }
            }

            .popup {
                position: absolute;
                top: 100px;
                right: 150px;
                width: 10%;
                padding: 5px;
                background-color: white;
                border: 1px solid #ccc;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                z-index: 1000;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                object-fit: cover;
                
              
                button {
                    padding: 10px;
                    margin: 0 0;
                    border: none;
                    background-color: white;
                    cursor: pointer;
                    font-weight: 500;
                    object-fit: cover;
                
              
                    &:hover {
                        background-color: #e0e0e0;
                    }
                }   
            }

            .emojiPopUp{
                position: absolute;
                top: auto;
                left: auto;
                width: auto;
                padding: 5px;
                z-index: 1000;
                object-fit: cover;
            }

            .messages {
                //background-color: #ddddf7;
                padding: 10px;
                height: calc(100% - 160px);
                overflow-y: scroll;

                .message {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 5px;

                    .messageInfo {
                        display: flex;
                        flex-direction: column;
                        color: gray;
                        font-weight: 300;

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    &:hover{
                        cursor: pointer;
                    }

                    .messageContent {
                        max-width: 80%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        p {
                            background-color: white;
                            padding: 10px 20px;
                            border-radius: 0px 10px 10px 10px;
                            max-width: max-content;
                            border: 1px solid gray;
                        }

                        img {
                            width: 50%;
                        }
                        span{
                            margin-top: -20px;
                            font-size: 10px;
                        }
                    }
                    .messageContentDoc{
                        background-color: #f0f0f0;
                        padding: 10px;
                        border-radius: 8px;
                        margin-bottom: 10px;
                        position: relative;
                        p {
                            background-color: #8da4f1;
                            color: white;
                            border-radius: 10px 0px 10px 10px;
                        }
                        .downloadLink {
                            display: inline-block;
                            margin-left: 10px;
                        }
                        .downloadIcon {
                            width: 20px; /* Adjust icon size as needed */
                            height: 20px;
                            vertical-align: middle;
                          }
                          .timestamp {
                            font-size: 12px;
                            color: #666;
                          }
                          .msg-status{
                            margin-top: 30px;
                            font-size: 12px;
                            color: #666;
                          }
                    }

                    &.owner {
                        flex-direction: row-reverse;

                        .messageContent {
                            align-items: flex-end;

                            p {
                                background-color: #8da4f1;
                                color: white;
                                border-radius: 10px 0px 10px 10px;
                            }
                        }

                        .metadata{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            
                        }
                        .messageContentDoc{
                            background-color: #f0f0f0;
                            padding: 10px;
                            border-radius: 8px;
                            margin-bottom: 10px;
                            position: relative;
                            p {
                                background-color: #8da4f1;
                                color: white;
                                border-radius: 10px 0px 10px 10px;
                            }
                            .downloadLink {
                                display: inline-block;
                                margin-left: 10px;
                            }
                            .downloadIcon {
                                width: 20px; /* Adjust icon size as needed */
                                height: 20px;
                                vertical-align: middle;
                              }
                              .timestamp {
                                font-size: 12px;
                                color: #666;
                              }
                              .msg-status{
                                font-size: 12px;
                                color: #666;
                              }


                        }
                    }                        
                }
            }
            .metadata{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                
            }
            .input {
                height: 50px;
                background-color: white;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #ccc;
                padding-top: 10px;

                .emojiIcon{
                    padding-right: 10px;
                    cursor: pointer;
                    z-index: 1000;
                    bottom: 0px;
                    right: 15px;
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    color: #2f2d52;
                    font-size: 18px;

                    &::placeholder {
                        color: lightgray;
                    }
                }

                select {
                    border: none;
                    outline: none;
                    color: #2f2d52;
                    width: auto;
                }

                .send {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img {
                        height: 24px;
                        cursor: pointer;
                        border-radius: 50%;

                    }

                    button {
                        border: none;
                        padding: 10px 15px;
                        color: white;
                        background-color: #8da4f1;
                        cursor: pointer;
                        border-radius: 20px;
                    }
                }
            }
        }

        .welcome{
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: white;
            background-image: 100%;
            background-image: url('../src/images/Chatbackground.png');
            

            h3{
                font-size: 32px;
                color: black;
            }
            p{
                font-size: 24px;
                color: #2f2d52;
                font-style: italic;
                font: bold;
            }
        }

        .profile{
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: white;
            
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
            

            .head{
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                color: lightgray;
                background-color: #2f2d52;

                button{
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    border: white;
                    cursor: pointer;

                    &:hover{
                        background-color: #e0e0e0;
                    }
                }
                
            }

            .body{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;
                height: 300px;
                border-bottom: 1px solid lightgray;

                .image-frame{
                    width: 60%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    border: 2px solid #ccc;
                    border-radius: 10%;
                    cursor: grab;

                    .image-frame:active{
                        cursor: grabbing;
                    }

                    .draggable-image{
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        user-select: none;
                        -webkit-user-drag: none;
                        object-fit: cover;
                    }
                
                }

            }

            .foot{
                display: flex;
                flex-direction: column;
                height: auto;

                .info{
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .call{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #2f2d52;
                    color: lightgray;
                    height: 100%;
                    
                    
                    .callButton{
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        gap: 10px;
                        border-radius: 10%;

                        &:hover{
                            cursor: pointer;
                        }
    
                        img{
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            padding: 2px;
                            border: white;
                            cursor: pointer;
                        }
    
                    }

                }

                .videoCall{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #2f2d52;
                    color: lightgray;
                    
                
                    .localVideo, .remoteVideo{
                        width: 100px;
                        height: 80px;
                        background-color: #2f2d52;
                        padding: 10px;
                        cursor: pointer;
                    }

                    .callButton{
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        gap: 10px;
                        border-radius: 10%;

                        &:hover{
                            cursor: pointer;
                        }
    
                        img{
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            padding: 2px;
                            border: white;
                            cursor: pointer;
                        }
    
                    }                    
                
                }


            }   

        }
        
    }
    .green-dot {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
        display: inline-block;
    }
    .red-dot {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
    }
}

/*
.modal{
    width: 200px;
    height: 400vh;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;


    button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}
*/

/*
.container {
    display: flex;
    height: 100vh;
}

.chat-list {
    width: 200px;
    background-color: #ededed;
    padding: 20px;
    border-right: 1px solid #ccc;
}

.chat-list h2 {
    margin-bottom: 20px;
}

.chat-list div {
    padding: 10px 20px;
    cursor: pointer;
}

.chat-list div.active {
    background-color: #f0f0f0;
}

.chat-area {
    flex: 1;
    padding: 20px;
}

.chat-window {
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 20px;
    display: flex;
    width: 85%;
    height: inherit;
    flex-direction: column;
    height: calc(100vh - 90px);
}

.header {
    background-color: #f0f0f0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    // border-radius: 10px 10px 0 0;
}

.header h3 {
    margin: 0;
}

.header button {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.header button:hover {
    background-color: #d32f2f;
}

.header select {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

.chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    
}

.input-group {
    display: flex;
    align-items: baseline;
    margin-top: 0px;
    border-top: 1px solid #ccc;
    padding-top: 0px;
    
}

.input-group input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
}

.input-group button {
    padding: 10px 20px;
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.input-group button:hover {
    background-color: #0056b3;
}

.incoming-message {
    background-color: #f0f0f0;
    padding: 10px;
    width: 50%;
    float: left;
    border-radius: 10px;
    margin-bottom: 10px;
    clear: both;
}

.outgoing-message {
    background-color: #0084ff;
    color: white;
    padding: 10px;
    width: 50%;
    float: right;
    text-align: right;
    border-radius: 10px;
    margin-bottom: 10px;
    clear: both;
}

.container input,
.container select {
    margin-bottom: 10px;
    
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.container button {
    margin-top: 10px;
}
*/